<template>
    <div class="positionT0L0">
        <a-alert message="" type="success">
            <template #description>
                <p>用户姓名：林黛玉</p>
                <p>用户手机号：13520209063</p>
            </template>
        </a-alert>
        <div class="contant-box">
            <a-table ref="table" :columns="columns" :data-source="tableList" :row-key="record => record.id" :loading="tableLoading" :pagination="false">
                <span slot="number" slot-scope="number, record, index">
                    <span>{{ index + 1 }}</span>
                </span>
                <img v-viewer class="avatar" slot="imageUrl" slot-scope="imageUrl, record" :src="record.imageUrl" alt="头像"/>
                <span slot="putawayFlagText" slot-scope="putawayFlagText, record">
                    <a-badge v-show="record.putawayFlag == 0" status="error" text="已下架"/>
                    <a-badge v-show="record.putawayFlag == 1" status="processing" text="定时上架"/>
                    <a-badge v-show="record.putawayFlag == 2" status="success" text="已上架"/>
                    <a-badge v-show="record.putawayFlag == 3" status="warning" text="暂不上架"/>
                </span>
            </a-table>
        </div>
        <!-- 分页功能 -->
        <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>

        <footer-tool-bar :collapsed="sideCollapsed">
            <a-button @click="$router.go(-1)">返回</a-button>
        </footer-tool-bar>
    </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import {message} from "ant-design-vue";
import { GetBannerApi} from "@/request/api/indexManage";
import { baseMixin } from "@/store/app-mixin";
import FooterToolBar from "@/components/FooterToolbar";
export default {
    mixins: [baseMixin],
    components: {MyPagination,FooterToolBar},
    created() {
        this.getTagList()
    },
    data() {
        return {
            spinning: false, // 全局loading
            tableLoading: false,
            columns: [
                {
                    title: "时间",
                    dataIndex: "number",
                    key: "number",
                },
                {
                    title: "变更来源",
                    dataIndex: "imageUrl",
                    key: "imageUrl",
                },
                {
                    title: "变更描述",
                    dataIndex: "name",
                    key: "name",
                },
                {
                    title: "课程/商品名",
                    dataIndex: "putawayFlagText",
                    key: "putawayFlagText",
                },
                {
                    title: "推广人信息",
                    dataIndex: "createTime",
                    key: "createTime",
                },
                {
                    title: "是否付费",
                    dataIndex: "createTime1",
                    key: "createTime1",
                }
            ],
            tableList: [],

            pageNo: 1, // 当前页
            pageSize: 20, // 每页条数
            count: 0, // 列表的总条数
        };
    },
    methods: {
        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.courseInfoFn();
        },

        // 列表数据
        getTagList() {
            this.tableLoading = true;
            GetBannerApi({
                pageNo: this.pageNo,
                pageSize: this.pageSize
            }).then((res) => {
                if (res.code === 200) {
                    this.tableList = res.data.data;
                    this.count = res.data.count;
                }
                this.tableLoading = false;
            });
        },
    },
};
</script>

<style lang="less" scoped>
.header {
    width: 100%;
    padding: 10px 20px;
    background-color: rgba(210, 210, 210, 0.27);
    line-height: 30px;
    font-weight: 500;
}

.contant-box{
    margin-bottom: 20px;
    margin-right: 20px;
}
</style>
